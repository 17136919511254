import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { SharedService } from './shared.service'

@Injectable({
    providedIn: 'root',
})
export class OrderworkService {
    constructor(private authService: AuthService, private sharedService: SharedService, private router: Router) {}

    read(formType, params, queryParams, overrideClear = null) {
        const {
            orderworkId,
            customerId,
            bootswatch,
            postal,
            number,
            addition,
            admin: adminParam,
            clear,
            timeZone,
            gtmContainerId,
            gaTrackingId,
        } = queryParams
        const admin = adminParam === 'true'
        const isClear = overrideClear || clear === 'true'
        const localStorageAvailable = this.localStorageTest()
        const language = params.language
        const affiliate = params.affiliate

        // Get _id from storage if present
        if (isClear && localStorageAvailable) localStorage.removeItem(`${formType}-orderworkId`)
        const localOrderworkId = localStorageAvailable ? localStorage.getItem(`${formType}-orderworkId`) : null

        // Get query params
        const opts = { params: { admin } }
        if (orderworkId) opts.params[`orderworkId`] = orderworkId
        else if (customerId) opts.params['customerId'] = customerId
        else if (postal) {
            opts.params['postal'] = postal
            if (number) opts.params['number'] = number
            if (addition) opts.params['addition'] = addition
        } else if (localOrderworkId && localOrderworkId.length === 24) opts.params[`orderworkId`] = localOrderworkId

        // Remove one time params
        this.router.navigate([], { queryParams: { timeZone, admin, bootswatch, gtmContainerId, gaTrackingId } })

        const url = `/api/form/${formType}/${language}/${affiliate}/`

        return this.authService.get(url, opts).pipe(
            tap((res) => this.sharedService.companyUpdate(res.data.company, bootswatch)),
            tap((res) => {
                const orderworkId = res.data.orderwork._id
                if (localStorageAvailable) localStorage.setItem(`${formType}-orderworkId`, orderworkId)
                else this.router.navigate([], { queryParams: { admin, bootswatch, orderworkId } })
            })
        )
    }

    private localStorageTest() {
        const test = 'test-if-localstore-is-working'
        try {
            localStorage.setItem(test, test)
            localStorage.removeItem(test)
            return true
        } catch (e) {
            return false
        }
    }

    save(orderwork) {
        const url = `/api/orderwork/${orderwork._id}/save/`
        const data = { orderwork }
        return this.authService.post(url, data)
    }

    continueLater(orderwork) {
        const url = `/api/orderwork/${orderwork._id}/continue-later/`
        return this.authService.get(url)
    }

    submit(orderworkId, orderworkType) {
        const url = `/api/calculate/${orderworkId}/`
        const data = { formSend: true }
        return this.authService.post(url, data).pipe(tap(() => localStorage.removeItem(`${orderworkType}-orderworkId`)))
    }

    calculateCosts(orderworkId) {
        const url = `/api/calculate/${orderworkId}/`
        const data = {}
        return this.authService.post(url, data)
    }

    checkCoupon(orderwork) {
        const url = `/api/coupon/valid/${orderwork.discount.code}`
        return this.authService.get(url)
    }
}
